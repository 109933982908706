import React, { ReactElement } from "react";
import {
  DisplayBodyHTML,
  DisplayBody,
  DisplayHeadingLarge,
} from "@Src/components/Text";
import BaseButton from "@Src/components/ui/BaseButton";
import Layout from "@Src/components/Layout";
import {
  AppOverlayProvider,
  AppProvider,
  NavThemeProvider,
} from "@Src/context/AppContext";
import Image from "./assets/Swirls1.png";
import * as styles from "./404.scss";
import { Link } from "gatsby";

// markup
const NotFoundPage = (): ReactElement => (
  <AppProvider type="index" path="/404">
    <AppOverlayProvider>
      <NavThemeProvider theme="creme">
        <Layout className="layout">
          <div className={styles.content}>
            <div className={styles.inner}>
              <div className="text">
                <DisplayHeadingLarge className={styles.heading}>
                  OOPS!
                </DisplayHeadingLarge>
                <DisplayBodyHTML
                  className={styles.body}
                  text="We can’t seem to find the page you are looking for.<br/>Error code: 404"
                />
                <DisplayBody className={styles.body} text="Helpful links:" />
                <Link to="/">
                  <BaseButton
                    className={styles.link}
                    link={{
                      url: "/",
                      id: "backtostart",
                      label: "Back to start",
                      primary: true,
                    }}
                    size="small"
                    buttonType="button"
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className="image">
            <img alt="notfound" src={Image} />
          </div>
        </Layout>
      </NavThemeProvider>
    </AppOverlayProvider>
  </AppProvider>
);

export default NotFoundPage;
